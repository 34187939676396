import './App.css';
import { useEffect } from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import Home from './Pages/Home';
import Exception from './Pages/Error';
import History from './Pages/History';
import Loading from './Pages/Loading';
import NotFound from './Pages/NotFound';
import Settings from './Pages/Settings';

import { setApiKey } from './Redux/ApiKeySlice';
import { ProfileStatus, getProfile, postProfile } from './Redux/ProfileSlice';
import { useAppDispatch, useAppSelector } from './Redux/Hooks';
import { getCurrencies } from './Redux/CurrencySlice';
import { getExpenseFolders } from './Redux/ExpenseFoldersSlice/ExpenseFolderThunks';
import { Status } from './Redux/ExpenseFoldersSlice/ExpenseFoldersSlice';
import { setIsFirstTimeLogin } from './Redux/Uislice';
import {
	getExpenseFolderPdfs,
	getExpenseFolderPdfsOld,
} from './Redux/ExpenseFolderPdfsSlice';
import { useTranslation } from 'react-i18next';
import { getBhOrders } from './Redux/BhExpensesSlice';
import { matomo } from './MatomoTracker';

const App = () => {
	const {
		loginWithRedirect,
		isAuthenticated,
		isLoading,
		error,
		user,
		getAccessTokenSilently,
	} = useAuth0();

	const profileReducer = useAppSelector((state) => state.profileReducer);

	const { i18n, t } = useTranslation();

	const expenseFolderStatus = useAppSelector(
		(state) => state.expenseFolderReducer.expenseFolderStatus,
	);

	const dispatch = useAppDispatch();

	const location = useLocation();
	const capitalizeFirstLetter = (input: string): string => {
		if (input.length === 0) return input;
		return input.charAt(0).toUpperCase() + input.slice(1);
	};
	useEffect(() => {
		const init = async (u: User) => {
			await getAccessTokenSilently().then(async (token) => {
				dispatch(setApiKey(token));
				let profile;
				try {
					profile = await dispatch(getProfile(u.TacNo)).unwrap();
				} catch {
					profile = await dispatch(postProfile(u)).unwrap();
					dispatch(setIsFirstTimeLogin(true));
				}
				await dispatch(getCurrencies());
				await dispatch(getExpenseFolders());
				await dispatch(getExpenseFolderPdfs());
				await dispatch(getExpenseFolderPdfsOld());
				await dispatch(
					getBhOrders({
						orderCount: 6,
						profileId: profile.id!,
					}),
				);
				matomo.trackEvent(
					'Login',
					'user successfully logged in',
					'',
					profile.tacNo,
				);
			});
		};
		if (user) init(user);
	}, [isAuthenticated, user]);

	useEffect(() => {
		matomo.trackPageView(location.pathname);
		if (location.pathname === '/settings')
			document.title =
				capitalizeFirstLetter(t('expense')) + ' | ' + t('settings');
		else if (location.pathname === '/history')
			document.title =
				capitalizeFirstLetter(t('expense')) + ' | ' + t('history');
		else
			document.title =
				capitalizeFirstLetter(t('expense')) + ' | ' + t('home');
	}, [location]);

	useEffect(() => {
		if (profileReducer.profile.languageCode?.toLowerCase() === 'no')
			i18n.changeLanguage('no');
		else i18n.changeLanguage('en');
	}, [profileReducer.profile.languageCode]);

	if (isLoading) return <Loading />;

	if (error !== undefined)
		return (
			<Exception
				title={error.name}
				description={error.message}
				displayLoggout={true}
			/>
		);

	if (profileReducer.status === ProfileStatus.FailedGetting)
		return (
			<Exception
				title={'500'}
				description={'Failed to get profile from server.'}
				displayLoggout={true}
			/>
		);

	if (!isAuthenticated) {
		loginWithRedirect();
		return <Loading message="Authenticating..." />;
	}

	if (
		profileReducer.status === ProfileStatus.Getting ||
		expenseFolderStatus === Status.Fetching ||
		!user
	)
		return <Loading message="Initializing..." />;

	return (
		<Layout>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/history" element={<History />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Layout>
	);
};

export default App;
