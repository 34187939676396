import moment from "moment";

type StringNullUndefined = string | undefined | null;

export const formatDate = (date: StringNullUndefined) => {
	if (!date) {
		return "";
	}
	return moment(date).format("DD.MM.yyyy");
};

export const formatDateInput = (date: string) => {
	return moment(new Date(date)).format("yyyy-MM-DD");
};

export const formatTimePeriod = (
	start: StringNullUndefined,
	end: StringNullUndefined,
) => {
	if (!start || !end) {
		return "";
	}
	return `${formatDate(start)} - ${formatDate(end)}`;
};
