import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BergHansenM3ClientModelsWebgateGetOrdersOrder } from "../Services/SwaggerClient";
import ApiClient from "../Services/ApiClient";
import { RootState } from "./Store";
export enum BhOrdersStatus {
	Idle,
	Getting,
	Updating,
	FailedGetting,
}

const initialState: BhOrdersState = {
	orders: [],
	status: BhOrdersStatus.Idle,
};

export interface BhOrdersState {
	orders: BergHansenM3ClientModelsWebgateGetOrdersOrder[];
	status: BhOrdersStatus;
}

export const getBhOrders = createAsyncThunk(
	"bhExpenses/getBhOrders",
	async (query: { orderCount: number; profileId: number }, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const res = await ApiClient(apiKey!).expense.getNonProcessedOrderDetail(
			query.profileId,
			{
				orderCount: query.orderCount,
			},
		);
		return res.data;
	},
);

export const processOrder = createAsyncThunk(
	"bhExpenses/processOrder",
	async (orderId: number, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.addProcessedOrderCreate(orderId);
		return orderId;
	},
);

export const bhExpensesSlice = createSlice({
	name: "bhExpenses",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getBhOrders.pending, (state) => {
				state.status = BhOrdersStatus.Getting;
			})
			.addCase(getBhOrders.fulfilled, (state, action) => {
				state.status = BhOrdersStatus.Idle;
				state.orders = action.payload ?? [];
			})
			.addCase(getBhOrders.rejected, (state) => {
				state.status = BhOrdersStatus.Idle;
			})
			.addCase(processOrder.pending, (state) => {
				state.status = BhOrdersStatus.Updating;
			})
			.addCase(processOrder.fulfilled, (state, action) => {
				state.status = BhOrdersStatus.Idle;
				state.orders = state.orders.filter(
					(order) => parseInt(order.orderNo!) !== action.payload,
				);
			});
	},
});

export default bhExpensesSlice.reducer;
