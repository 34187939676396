import { ExpenseFolder } from "../Models/ExpenseFolder";
import { concatExpenses } from "./ConcatExpenses";

export const findDateRange = (expenseFolder: ExpenseFolder) => {
	const sortedExpenses = concatExpenses(expenseFolder).sort(
		(e1, e2) => new Date(e1.date).getTime() - new Date(e2.date).getTime(),
	);
	if (sortedExpenses.length === 0) return { startDate: null, endDate: null };
	const startDate = sortedExpenses[0].date;
	const endDate = sortedExpenses[sortedExpenses.length - 1].date;
	return { startDate, endDate };
};

export const findDateDifference = (
	e1: { createdAt: string },
	e2: { createdAt: string },
): number =>
	new Date(e2.createdAt).getTime() - new Date(e1.createdAt).getTime();
