// src/features/apiKey/apiKeySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ApiKey {
	value: string | null;
}

const initialState: ApiKey = {
	value: null
};

const ApiKeySlice = createSlice({
	name: "apiKey",
	initialState,
	reducers: {
		setApiKey: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	}
});

export const { setApiKey } = ApiKeySlice.actions;
export default ApiKeySlice.reducer;
