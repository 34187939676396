import React, { useEffect, useState } from 'react';
import {
	TextField,
	Box,
	Typography,
	InputAdornment,
	Snackbar,
	Alert,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import { Profile } from '../Models/Profile';
import Loading from './Loading';
import CurrencyListComponent from '../Components/Shared/CurrencyListComponent';
import ResizingButton from '../Components/Shared/ResizingButton';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { ProfileStatus, putProfile } from '../Redux/ProfileSlice';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { setIsFirstTimeLogin } from '../Redux/Uislice';
import { useTranslation } from 'react-i18next';
import { matomo } from '../MatomoTracker';
import BackToHomeLink from '../Components/Shared/BackToHomeLink';
import NumberInputField from '../Components/Shared/NumberInputField';

const SettingsComponent: React.FC = () => {
	const [profile, setProfile] = useState<Profile>();

	const isOnPhone = useMediaQuery('(max-width:600px)');

	const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);

	const p = useAppSelector((state) => state.profileReducer);

	const [alertDisplayed, setAlertDisplayed] = useState(true);

	const [failedSnackbarOpen, setFailedSnackbarOpen] = useState(false);

	const [isTooltipSendToOpen, setIsTooltipSendToOpen] = useState(false);

	const [isTooltipRatePerKmOpen, setIsTooltipRatePerKmOpen] = useState(false);

	const [isTooltipRatePerPassengerOpen, setIsTooltipRatePerPassengerOpen] =
		useState(false);

	const [
		isTooltipKmAndRatePerPassengerOpen,
		setIsTooltipKmAndRatePerPassengerOpen,
	] = useState(false);

	const [isCurrencyFeeTooltipOpen, setIsCurrencyFeeTooltipOpen] =
		useState(false);

	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	const handleClose = () => {
		setSuccessSnackBarOpen(false);
		setFailedSnackbarOpen(false);
		setAlertDisplayed(true);
	};

	useEffect(() => {
		setProfile({ ...p.profile });
	}, []);

	useEffect(() => {
		dispatch(setIsFirstTimeLogin(false));
	}, []);

	useEffect(() => {
		if (p.status === ProfileStatus.Idle && !alertDisplayed) {
			setSuccessSnackBarOpen(true);
		}
		if (p.status === ProfileStatus.FailedPutting && !alertDisplayed) {
			setFailedSnackbarOpen(true);
		}
	}, [p.status]);

	const handleCurrencyChange = (currencyCode: string) => {
		setProfile({
			...profile!,
			currencyCode: currencyCode,
		});
	};
	const handleSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setProfile({ ...profile!, sendTo: event.target.value });
	};
	const handleBankAccountChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setProfile({ ...profile!, bankAccount: event.target.value });
	};
	const handleRatePerKmChange = (ratePerKm: number) => {
		setProfile({
			...profile!,
			ratePerKm: ratePerKm || 0,
		});
	};
	const handleRatePerPassengerChange = (rate: number) => {
		setProfile({
			...profile!,
			ratePerPassenger: rate || 0,
		});
	};
	const handleCreditCardFeeChange = (creditCardFee: number) => {
		setProfile({
			...profile!,
			creditCardFee: creditCardFee,
		});
	};

	if (profile === undefined) {
		return <Loading />;
	}

	return (
		<Box>
			<BackToHomeLink />
			<Typography variant="h3" component="h3">
				{t('settings')}
			</Typography>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					setAlertDisplayed(false);
					dispatch(putProfile(profile));
					matomo.trackEvent(
						'Settings',
						'click',
						'Settings saved',
						profile.id,
					);
				}}
			>
				<Box display={'flex'} alignItems={'center'}>
					<Tooltip
						title={t('receiving_email_tooltip')}
						open={isTooltipSendToOpen}
						onMouseLeave={() => setIsTooltipSendToOpen(false)}
						onMouseEnter={() => {
							if (!isOnPhone) setIsTooltipSendToOpen(true);
						}}
					>
						<TextField
							label={t('send_to')}
							value={profile.sendTo}
							onChange={handleSendToChange}
							type="email"
							required
						></TextField>
					</Tooltip>
					<HelpOutlineRoundedIcon
						fontSize="small"
						sx={{ marginLeft: '0.4rem', marginTop: '0.4rem' }}
						onClick={() =>
							setIsTooltipSendToOpen(!isTooltipSendToOpen)
						}
					/>
				</Box>

				<CurrencyListComponent
					handleCurrencyChange={handleCurrencyChange}
					value={profile.currencyCode}
					size="large"
				/>
				<TextField
					label={t('account_number')}
					onChange={handleBankAccountChange}
					value={profile.bankAccount}
				></TextField>
				<Box display={'flex'} alignItems={'center'}>
					<Tooltip
						title={t('rate_per_km_tooltip')}
						open={isTooltipRatePerKmOpen}
						onMouseLeave={() => setIsTooltipRatePerKmOpen(false)}
						onMouseEnter={() => {
							if (!isOnPhone)
								return setIsTooltipRatePerKmOpen(true);
						}}
					>
						<NumberInputField
							label={t('rate_per_km')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										NOK
									</InputAdornment>
								),
							}}
							onChange={handleRatePerKmChange}
							value={profile.ratePerKm}
							sx={{ marginRight: '0.4rem' }}
						></NumberInputField>
					</Tooltip>
					<Tooltip
						title={t('rate_per_km_tooltip')}
						open={isTooltipRatePerPassengerOpen}
						onMouseLeave={() =>
							setIsTooltipRatePerPassengerOpen(false)
						}
						onMouseEnter={() => {
							if (!isOnPhone)
								setIsTooltipRatePerPassengerOpen(true);
						}}
					>
						<NumberInputField
							label={t('rate_per_passenger')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										NOK
									</InputAdornment>
								),
							}}
							InputLabelProps={{ shrink: true}}
							onChange={handleRatePerPassengerChange}
							value={profile.ratePerPassenger}
							sx={{ marginLeft: '0.4rem' }}
						></NumberInputField>
					</Tooltip>

					<Tooltip
						title={t('rate_per_km_tooltip')}
						open={isTooltipKmAndRatePerPassengerOpen}
					>
						<HelpOutlineRoundedIcon
							fontSize="small"
							sx={{ marginLeft: '0.4rem', marginTop: '0.4rem' }}
							onClick={() =>
								setIsTooltipKmAndRatePerPassengerOpen(
									!isTooltipKmAndRatePerPassengerOpen,
								)
							}
							onMouseLeave={() =>
								setIsTooltipKmAndRatePerPassengerOpen(false)
							}
						/>
					</Tooltip>
				</Box>

				<Box display={'flex'} alignItems={'center'}>
					<Tooltip
						title={t('currency_fee_tooltip')}
						open={isCurrencyFeeTooltipOpen}
						onMouseLeave={() => setIsCurrencyFeeTooltipOpen(false)}
						onMouseOver={() => setIsCurrencyFeeTooltipOpen(true)}
					>
						<NumberInputField
							value={profile.creditCardFee ?? 0}
							onChange={handleCreditCardFeeChange}
							maxValue={4.5}
							label={t('currency_fee')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										%
									</InputAdornment>
								),
							}}
						/>
					</Tooltip>
					<HelpOutlineRoundedIcon
						fontSize="small"
						sx={{ marginLeft: '0.4rem', marginTop: '0.4rem' }}
						onClick={() =>
							setIsCurrencyFeeTooltipOpen(
								!isCurrencyFeeTooltipOpen,
							)
						}
						onMouseLeave={() => setIsCurrencyFeeTooltipOpen(false)}
					/>
				</Box>
				<ResizingButton
					type="submit"
					disabled={p.status === ProfileStatus.Putting}
					style={{ float: 'right', marginTop: '0.5rem' }}
				>
					{t('save')}
				</ResizingButton>
			</form>
			<Snackbar
				open={successSnackBarOpen}
				onClose={handleClose}
				sx={{ marginTop: '30px' }}
			>
				<Alert severity="success">
					{t('your_changes_have_been_saved')}
				</Alert>
			</Snackbar>
			<Snackbar
				open={failedSnackbarOpen}
				onClose={handleClose}
				sx={{ marginTop: '30px' }}
			>
				<Alert severity="error">{t('error_updating_settings')}</Alert>
			</Snackbar>
		</Box>
	);
};

export default SettingsComponent;
