import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ExpenseFoldersState, Status } from "./ExpenseFoldersSlice";
import {
	postMileageAllowance,
	putMileageAllowance,
	postBaseExpense,
	putBaseExpense,
	postFlight,
	postRepresentation,
	putFlight,
	putRepresentation,
	postOtherTransport,
	putOtherTransport,
	deleteExpense,
} from "./ExpenseThunks";
import { concatExpenses } from "../../Utils/ConcatExpenses";
import { ExpenseName } from "../../Models/Expense";

const addMileageAllowanceCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(postMileageAllowance.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(postMileageAllowance.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			state
				.folders.find((f) => f.id === action.payload.expenseFolderId)!
				.mileageAllowances.push(action.payload);
		})
		.addCase(postMileageAllowance.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		})
		.addCase(putMileageAllowance.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(putMileageAllowance.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			const folder = state.folders.find(
				(f) => f.id === action.payload.expenseFolderId,
			)!;

			folder.mileageAllowances = folder.mileageAllowances.map((ma) =>
				ma.id === action.payload.id ? action.payload : ma,
			);
		})
		.addCase(putMileageAllowance.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		});
};

const addBaseExpenseCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(postBaseExpense.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(postBaseExpense.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			state.folders
				.find((f) => f.id === action.payload.expenseFolderId)!
				.baseExpenses.push(action.payload);
		})
		.addCase(postBaseExpense.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		})
		.addCase(putBaseExpense.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(putBaseExpense.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			const folder = state.folders.find(
				(f) => f.id === action.payload.expenseFolderId,
			)!;

			folder.baseExpenses = folder.baseExpenses.map((be) =>
				be.id === action.payload.id ? action.payload : be,
			);
		})
		.addCase(putBaseExpense.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		});
};

const addFlightCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(postFlight.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(postFlight.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			state.folders
				.find((f) => f.id === action.payload.expenseFolderId)!
				.flight.push(action.payload);
		})
		.addCase(postFlight.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		})
		.addCase(putFlight.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(putFlight.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			const folder = state.folders.find(
				(f) => f.id === action.payload.expenseFolderId,
			)!;

			folder.flight = folder.flight.map((be) =>
				be.id === action.payload.id ? action.payload : be,
			);
		})
		.addCase(putFlight.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		});
};

const addRepresentationCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(postRepresentation.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(postRepresentation.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			state.folders
				.find((f) => f.id === action.payload.expenseFolderId)!
				.representations.push(action.payload);
		})
		.addCase(postRepresentation.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		})
		.addCase(putRepresentation.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(putRepresentation.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			const folder = state.folders.find(
				(f) => f.id === action.payload.expenseFolderId,
			)!;

			folder.representations = folder.representations.map((be) =>
				be.id === action.payload.id ? action.payload : be,
			);
		})
		.addCase(putRepresentation.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		});
};

const addOtherTransportCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(postOtherTransport.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(postOtherTransport.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			state.folders
				.find((f) => f.id === action.payload.expenseFolderId)!
				.otherTransports.push(action.payload);
		})
		.addCase(postOtherTransport.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		})
		.addCase(putOtherTransport.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(putOtherTransport.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			const folder = state.folders.find(
				(f) => f.id === action.payload.expenseFolderId,
			)!;

			folder.otherTransports = folder.otherTransports.map((be) =>
				be.id === action.payload.id ? action.payload : be,
			);
		})
		.addCase(putOtherTransport.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		});
};

const addDeleteExpenseCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	builder
		.addCase(deleteExpense.pending, (state) => {
			state.expenseStatus = Status.Fetching;
		})
		.addCase(deleteExpense.fulfilled, (state, action) => {
			state.expenseStatus = Status.Idle;
			const expense = state.folders
				.flatMap((f) => concatExpenses(f))
				.find((e) => e.id === action.payload);
			const index = state.folders.findIndex(
				(f) => f.id === expense!.expenseFolderId,
			);

			switch (expense!.expenseName) {
				case ExpenseName.Flight:
					state.folders[index].flight = state.folders
						.find((f) => f.id === expense!.expenseFolderId)!
						.flight.filter((f) => f.id !== action.payload);
					break;
				case ExpenseName.MileageAllowance:
					state.folders[index].mileageAllowances = state.folders
						.find((f) => f.id === expense!.expenseFolderId)!
						.mileageAllowances.filter(
							(f) => f.id !== action.payload,
						);
					break;
				case ExpenseName.OtherTransport:
					state.folders[index].otherTransports = state.folders
						.find((f) => f.id === expense!.expenseFolderId)!
						.otherTransports.filter((f) => f.id !== action.payload);
					break;
				case ExpenseName.Representation:
					state.folders[index].representations = state.folders
						.find((f) => f.id === expense!.expenseFolderId)!
						.representations.filter((f) => f.id !== action.payload);
					break;
				default:
					state.folders[index].baseExpenses = state.folders
						.find((f) => f.id === expense!.expenseFolderId)!
						.baseExpenses.filter((f) => f.id !== action.payload);
			}
		})
		.addCase(deleteExpense.rejected, (state) => {
			state.expenseStatus = Status.Idle;
		});
};

export const addExpenseCases = (
	builder: ActionReducerMapBuilder<ExpenseFoldersState>,
) => {
	addMileageAllowanceCases(builder);
	addBaseExpenseCases(builder);
	addFlightCases(builder);
	addRepresentationCases(builder);
	addOtherTransportCases(builder);
	addDeleteExpenseCases(builder);
};
