import { configureStore } from "@reduxjs/toolkit";
import apiServiceReducer from "./ApiKeySlice";
import profileReducer from "./ProfileSlice";
import currencyReducer from "./CurrencySlice";
import bhExpensesReducer from "./BhExpensesSlice";

import expenseFolderReducer from "./ExpenseFoldersSlice/ExpenseFoldersSlice";
import expenseFolderPdfReducer from "./ExpenseFolderPdfsSlice";
import uiReducer from "./Uislice";

export const store = configureStore({
	reducer: {
		apiKey: apiServiceReducer,
		profileReducer: profileReducer,
		currencyReducer: currencyReducer,
		expenseFolderReducer: expenseFolderReducer,
		expenseFolderPdfReducer: expenseFolderPdfReducer,
		uiReducer: uiReducer,
		bhExpensesReducer: bhExpensesReducer,
	},
	devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
