import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ExpenseFolderPdf } from "../Models/ExpenseFolderPdf";
import { RootState } from "./Store";
import { toExpenseFolderPdf, toExpenseFolderPdfDto } from "../Models/Mappings";
import ApiClient from "../Services/ApiClient";

export enum ExpenseFolderPdfStatus {
	Idle,
	Getting,
	Posting,
	FailedGetting,
	FailedPosting,
}

const initialState: ExpenseFolderPdfState = {
	pdfsExpense: [],
	status: ExpenseFolderPdfStatus.Idle,
	pdfsOld: [],
};

export interface ExpenseFolderPdfState {
	pdfsExpense: ExpenseFolderPdf[];
	pdfsOld: ExpenseFolderPdf[];
	status: ExpenseFolderPdfStatus;
}

export const getExpenseFolderPdfs = createAsyncThunk(
	"pdf/fetchPdfs",
	async (_, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const profile = (thunkApi.getState() as RootState).profileReducer
			.profile;
		const apiClient = ApiClient(apiKey!);
		const res = await apiClient.expense.getExpenseFolderPdfsDetail(
			profile.id,
		);
		return [...res.data].map(toExpenseFolderPdf);
	},
);

export const getExpenseFolderPdfsOld = createAsyncThunk(
	"pdf/fetchPdfsOld",
	async (_, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const profile = (thunkApi.getState() as RootState).profileReducer
			.profile;
		const res = await ApiClient(
			apiKey!,
		).expense.getExpenseFolderPdfsTravelexpenseDetail(profile.tacNo);
		return res.data.map(toExpenseFolderPdf);
	},
);

export const postExpenseFolderPdf = createAsyncThunk(
	"pdf/postPdf",
	async (pdf: ExpenseFolderPdf, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const res = await ApiClient(apiKey!).expense.postExpenseFolderPdfCreate(
			toExpenseFolderPdfDto(pdf),
		);
		return toExpenseFolderPdf(res.data);
	},
);

export const expenseFolderPdfsSlice = createSlice({
	name: "pdf",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getExpenseFolderPdfs.pending, (state) => {
				state.status = ExpenseFolderPdfStatus.Getting;
			})
			.addCase(getExpenseFolderPdfs.fulfilled, (state, action) => {
				state.status = ExpenseFolderPdfStatus.Idle;
				state.pdfsExpense = action.payload;
			})
			.addCase(getExpenseFolderPdfs.rejected, (state) => {
				state.status = ExpenseFolderPdfStatus.FailedGetting;
			})
			.addCase(postExpenseFolderPdf.pending, (state) => {
				state.status = ExpenseFolderPdfStatus.Posting;
			})
			.addCase(postExpenseFolderPdf.fulfilled, (state, action) => {
				state.status = ExpenseFolderPdfStatus.Idle;
				state.pdfsExpense.push(action.payload);
			})
			.addCase(postExpenseFolderPdf.rejected, (state) => {
				state.status = ExpenseFolderPdfStatus.FailedPosting;
			})
			.addCase(getExpenseFolderPdfsOld.pending, (state) => {
				state.status = ExpenseFolderPdfStatus.Getting;
			})
			.addCase(getExpenseFolderPdfsOld.fulfilled, (state, action) => {
				state.status = ExpenseFolderPdfStatus.Idle;
				state.pdfsOld = action.payload;
			})
			.addCase(getExpenseFolderPdfsOld.rejected, (state) => {
				state.status = ExpenseFolderPdfStatus.FailedGetting;
			});
	},
});

export default expenseFolderPdfsSlice.reducer;
