import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	container: {
		position: 'relative',
		width: '49%',
		height: 130, // Adjust height as needed
		marginBottom: 10,
		marginLeft: 'auto',
		borderStyle: 'solid',
		borderColor: '#bfbfbf',
		borderWidth: 1,
	},
	image: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
	textOverlay: {
		padding: '7px',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between', // To align text at the top and bottom
		fontSize: '10',
	},
	topText: {
		// New style for top text
		color: '#808080', // Grey color
	},
	bottomText: {
		// New style for bottom text
		color: '#808080', // Grey color, adjust as needed
		marginTop: '25px', // Ensures bottom alignment
	},
});

interface BottomTextProps {
	date: string;
	name: string;
}

export const BottomText = (props: BottomTextProps) => {
	return (
		<View style={styles.container}>
			<Image style={styles.image} src={'Vannmerke.png'} />
			<View style={styles.textOverlay}>
				<Text style={styles.topText}>
					Dette dokumentet er signert elektronisk av
				</Text>
				<Text style={{ fontWeight: 'bold' }}>{props.name}</Text>
				<Text>{props.date}</Text>
				<Text style={styles.bottomText}>
					Signeringen er loggført av Berg-Hansen Reisebureau AS
				</Text>
			</View>
		</View>
	);
};
