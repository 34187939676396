import { Attachment } from "./Attachment";

export enum ExpenseName {
	Other = "Other",
	OtherTransport = "OtherTransport",
	RentalCar = "RentalCar",
	MileageAllowance = "MileageAllowance",
	Flight = "Flight",
	Train = "Train",
	Meal = "Meal",
	Representation = "Representation",
	Hotel = "Hotel",
	Parking = "Parking",
}

export enum ExpenseStatus {
	Posting,
	Putting,
	Failed,
}

export interface Expense {
	id: number;
	amount: number;
	currencyCode: string;
	expenseFolderId: number;
	expenseName: ExpenseName;
	createdAt: string;
	date: string;
	exchangeDetails?: {
		amountInTargetCurrency: number;
		exchangeRate: number;
		targetCurrency: string;
	};
	attachments?: Attachment[];
	m3OrderNumber?: number;
}

export interface BaseExpense extends Expense {
	description?: string;
}

export interface Flight extends Expense {
	startLocation: string;
	endLocation: string;
}

export interface OtherTransport extends Expense, BaseExpense, Flight {}

export interface Representation extends Expense {
	who: string;
	where: string;
}

export interface MileageAllowance extends Expense, Flight {
	distanceInKm: number;
	passengerCount: number;
}

export type ExpenseUnion =
	| BaseExpense
	| Flight
	| Representation
	| OtherTransport
	| MileageAllowance;
