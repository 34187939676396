import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Profile } from "../Models/Profile";
import { RootState } from "./Store";
import {
	toProfile,
	toProfileDto,
	toProfileDtoFromUser,
} from "../Models/Mappings";
import { User } from "@auth0/auth0-react";
import ApiClient from "../Services/ApiClient";

export enum ProfileStatus {
	Idle,
	Updated,
	Getting,
	Posting,
	Putting,
	NotFound,
	FailedGetting,
	FailedPosting,
	FailedPutting,
}

const initialState: ProfileState = {
	profile: {
		currencyCode: "USD",
		id: 0,
		email: "",
		firstName: "",
		lastName: "",
		tacNo: 0,
		bankAccount: "",
		languageCode: "",
		creditCardFee: 0,
		ratePerKm: 0,
		ratePerPassenger: 0,
		sendTo: "",
	},
	status: ProfileStatus.Idle,
};

export interface ProfileState {
	profile: Profile;
	status: ProfileStatus;
}

export const getProfile = createAsyncThunk(
	"profile/getProfile",
	async (tacNo: number, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const res = await ApiClient(apiKey!).expense.getProfileFromTacNoDetail(
			tacNo,
		);
		return res.data;
	},
);

export const putProfile = createAsyncThunk(
	"profile/putProfile",
	async (profile: Profile, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putProfileUpdate(
			toProfileDto(profile),
		);
		return toProfile(profile);
	},
);

export const postProfile = createAsyncThunk(
	"profile/postProfile",
	async (profile: User, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const response = await ApiClient(apiKey!).expense.postProfileCreate(
			toProfileDtoFromUser(profile),
		);
		return toProfile(response.data); //todo: fiks mapping
	},
);

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProfile.pending, (state) => {
				state.status = ProfileStatus.Getting;
			})
			.addCase(getProfile.fulfilled, (state, action) => {
				state.status = ProfileStatus.Idle;

				state.profile = toProfile(action.payload);
			})
			.addCase(getProfile.rejected, (state, action) => {
				state.status =
					action.error.code == "404"
						? ProfileStatus.NotFound
						: ProfileStatus.FailedGetting;
			})
			.addCase(putProfile.pending, (state) => {
				state.status = ProfileStatus.Putting;
			})
			.addCase(putProfile.fulfilled, (state, action) => {
				state.status = ProfileStatus.Idle;
				state.profile = toProfile(action.payload);
			})
			.addCase(putProfile.rejected, (state, action) => {
				state.status = ProfileStatus.FailedPutting;
			})
			.addCase(postProfile.pending, (state) => {
				state.status = ProfileStatus.Posting;
			})
			.addCase(postProfile.fulfilled, (state, action) => {
				state.status = ProfileStatus.Idle;
				state.profile = toProfile(action.payload);
			})
			.addCase(postProfile.rejected, (state, action) => {
				state.status = ProfileStatus.FailedPosting;
			});
	},
});

export default profileSlice.reducer;
