import { Box, Divider, IconButton, Typography } from '@mui/material';
import { ExpenseUnion } from '../Models/Expense';
import EditIcon from '@mui/icons-material/Edit';
import { ExpenseDescription } from '../Utils/ExpenseDescription';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { openEditExpenseDialog } from '../Redux/Uislice';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { GetExpenseName as GetExpenseNames } from './Shared/ExpenseFormFields';
import { formatNumberWithSpaces } from './PdfDocument';

interface ExpenseListItemProps {
	expense: ExpenseUnion;
}

const ExpenseComponent: React.FC<ExpenseListItemProps> = ({ expense }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const profile = useAppSelector((state) => state.profileReducer.profile);
	const expenseName = GetExpenseNames()
		.find((s) => expense.expenseName.toString() === s)
		?.toString();
	return (
		<>
			<Box
				display="flex"
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Box display="flex" alignItems={'center'}>
					<Typography
						variant="body2"
						display={'flex'}
						alignItems={'center'}
					>
						{expenseName && t(expenseName)}:{' '}
						{ExpenseDescription(expense, t, profile)}
						{expense.attachments &&
							expense.attachments.length > 0 && (
								<AttachFileIcon fontSize="small" />
							)}
					</Typography>
				</Box>

				<Box display={'flex'} alignItems={'center'}>
					<Typography variant="body2" alignItems={'center'}>
						{formatNumberWithSpaces(expense.amount)}{' '}
						{expense.currencyCode}
					</Typography>
					<IconButton
						onClick={() =>
							dispatch(
								openEditExpenseDialog({
									expenseToEditId: expense.id,
								}),
							)
						}
					>
						<EditIcon
							titleAccess={t('edit_receipt')}
							fontSize="small"
						/>
					</IconButton>
				</Box>
			</Box>

			<Divider sx={{ marginBottom: '1rem' }} />
		</>
	);
};

export default ExpenseComponent;
