import React from 'react';
import {
	Box,
	Typography,
	IconButton,
	Alert,
	Snackbar,
	CircularProgress,
} from '@mui/material';
import { FileUploadOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { fileToBase64 } from '../AddExpenseFormComponent';
import ApiClient from '../../Services/ApiClient';
import { useAppSelector } from '../../Redux/Hooks';

interface AttachmentProps {
	onAttachmentUpload: (file: File) => void;
}

const PhotoCapture: React.FC<AttachmentProps> = ({ onAttachmentUpload }) => {
	const { t } = useTranslation();

	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	const token = useAppSelector((state) => state.apiKey.value);

	// Define the allowed MIME types for validation
	const allowedMimeTypes = [
		'image/jpeg',
		'image/jpg',
		'image/png',
		'application/pdf',
	];

	const [isUploadAttachmentAlert, setIsUploadAttachmentAlert] =
		React.useState<boolean>(false);

	// Function to validate the uploaded file
	const validateImageFile = (file: File): boolean => {
		return allowedMimeTypes.includes(file.type);
	};

	const [isValidating, setIsValidating] = React.useState<boolean>(false);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		const data = fileToBase64(file!);
		if (file && !validateImageFile(file)) {
			setIsUploadAttachmentAlert(true);
			hiddenFileInput.current!.value = '';
			return;
		}

		data.then((base64) => {
			setIsValidating(true);
			return ApiClient(token!)
				.expense.validateAttachmentDataCreate({
					description: file!.name,
					data: base64,
					mediaType: file!.type,
				})
				.then((res) => {
					if (!res.data.isValid) {
						setIsUploadAttachmentAlert(true);
						hiddenFileInput.current!.value = '';
						setIsValidating(false);
						return;
					}
					file != undefined && onAttachmentUpload(file);
					setIsValidating(false);
					event.target.value = '';
				})
				.catch((error) => {
					setIsValidating(false);
				});
		});
	};

	return (
		<>
			<input
				type="file"
				onChange={handleFileChange}
				style={{ display: 'none' }}
				ref={hiddenFileInput}
				accept=".pdf, .jpg, .jpeg, .png"
			/>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'center'}
				alignItems={'center'}
				onClick={() => hiddenFileInput.current!.click()}
				sx={{
					paddingTop: '1.3rem',
					paddingBottom: '1.3rem',
					border: '2px dashed',
					borderColor: 'rgba(0, 0, 0, 0.12)', // Black with 12% opacity
					borderRadius: '5px',
					cursor: 'pointer',
				}}
			>
				{(isValidating && <CircularProgress />) || (
					<>
						<Typography
							style={{
								marginRight: '0.3rem',
							}}
						>
							{t('take_photo')}
						</Typography>
						<Typography>{t('or')}</Typography>

						<Typography
							style={{
								marginLeft: '0.3rem',
							}}
						>
							{t('upload_file')}
						</Typography>
						<IconButton>
							<FileUploadOutlined />
						</IconButton>
					</>
				)}
			</Box>
			<Snackbar
				open={isUploadAttachmentAlert}
				onClose={() => setIsUploadAttachmentAlert(false)}
				autoHideDuration={6000}
			>
				<Alert severity="error">
					<Typography>{t('upload_file_error')}</Typography>
				</Alert>
			</Snackbar>
		</>
	);
};

export default PhotoCapture;
