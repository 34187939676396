import { createTheme } from '@mui/material';

const theme = createTheme({
	palette: {
		primary: {
			main: '#3057e4',
		},
		secondary: {
			main: '#6cdaec',
		},
		background: {
			default: '#F3F3F3',
		},
		grey: {
			'100': '#CCCCCC',
		},
	},

	typography: {
		fontFamily: 'Inter, Arial',
	},

	components: {
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				margin: 'normal',
				fullWidth: true,
				size: 'small',
			},
		},

		MuiAppBar: {
			defaultProps: {
				sx: {
					color: 'black',
					boxShadow: 'none',
					backgroundColor: 'white',
					borderBottomStyle: 'solid',
					borderBottomColor: 'grey.100',
					borderBottomWidth: '1px',
				},
			},
		},
		MuiSnackbar: {
			defaultProps: {
				autoHideDuration: 5000,
				anchorOrigin: { vertical: 'top', horizontal: 'center' },
				sx: { marginTop: '30px' },
			},
		},

		MuiButton: {
			defaultProps: {
				variant: 'contained',
				color: 'primary',
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
					fontSize: '0.95rem',
				},
			},
		},

		MuiAlert: {
			defaultProps: {
				variant: 'filled',
			},
		},

		MuiLink: {
			defaultProps: {
				underline: 'none',
				margin: 'theme.spacing(1, 1,5)',
				color: 'white',
				align: 'center',
			},
		},

		MuiInputBase: {
			styleOverrides: {
				input: {
					'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
						{
							WebkitAppearence: 'none',
							margin: 0,
							display: 'none',
						},

					'&[type=number]': {
						MozAppearance: 'textfield',
					},
				},
			},
		},

		MuiTypography: {
			defaultProps: {},
			variants: [
				{
					props: { variant: 'h2' },
					style: {
						fontWeight: 500,
					},
				},
				{
					props: { variant: 'h3' },
					style: {
						fontSize: '2.188rem',
						fontWeight: 600,
					},
				},
				{
					props: { variant: 'h6' },
					style: {
						fontWeight: 600,
					},
				},
			],
		},
	},
});

export default theme;
