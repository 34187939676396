import CloseIcon from '@mui/icons-material/Close';
import { IconButton, IconButtonProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CloseIconButtonComponent = (props: IconButtonProps) => {
	const { t } = useTranslation();
	return (
		<IconButton
			aria-label="close"
			onClick={props.onClick}
			sx={{
				position: 'absolute',
				right: 0.1,
				top: 0.1,
				color: (theme) => theme.palette.grey[500],
			}}
		>
			<Typography variant="body2" > {t('close')}</Typography> <CloseIcon fontSize='small'/>
		</IconButton>
	);
};

export default CloseIconButtonComponent;
