import { Button, ButtonProps, useMediaQuery } from '@mui/material';

const ResizingButton = (props: ButtonProps) => {
	const isSmallScreen = useMediaQuery('(max-width: 600px)');
	return (
		<Button
			size={isSmallScreen ? 'small' : 'medium'}
			{...props}
		>
			{props.children}
		</Button>
	);
};

export default ResizingButton;
