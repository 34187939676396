import { Button, Typography } from "@mui/material"
import { t } from "i18next"
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

const backToHomeLink = () => {
    return (
        <Link display={'flex'} color="blue" component={RouterLink} to={'/'}>
            <Button variant="text">
                <ArrowBackIcon />
                <Typography marginLeft={'0.2rem'}>
                    {t('back_to_home')}
                </Typography>
            </Button>
        </Link>
    )
}

export default backToHomeLink;