import { LoadingButton as MuiLoadingButton, LoadingButtonProps } from '@mui/lab';
import { useMediaQuery } from '@mui/material';

const LoadingButton = (props: LoadingButtonProps) => {
	const isSmallScreen = useMediaQuery('(max-width: 600px)');
	return (
		<MuiLoadingButton size={isSmallScreen ? 'small' : 'medium'} {...props}>
			{props.children}
		</MuiLoadingButton>
	);
};

export default LoadingButton;
