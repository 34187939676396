import { View, Text } from '@react-pdf/renderer';

interface TextBoxProps {
	textInBoxOne: string;
	textInBoxTwo: string;
}

import { styles } from '../PdfDocument';

export const TextBox = (props: TextBoxProps) => {
	return (
		<View style={styles.flexRow}>
			<Text style={styles.textBox}>{props.textInBoxOne}</Text>
			<Text style={styles.textBox}>{props.textInBoxTwo}</Text>
		</View>
	);
};
