import { Box } from '@mui/material';
import ResizingButton from './ResizingButton';
import LoadingButton from './LoadingButton';
import { useTranslation } from 'react-i18next';
import { matomo } from '../../MatomoTracker';

interface AddDialogButtonsProps {
	isAdding: boolean;
	closeDialog: () => void;
	textCode: string;
}

const AddDialogButtons = (props: AddDialogButtonsProps) => {
	const { isAdding: isSaving, closeDialog, textCode } = props;
	const { t } = useTranslation();
	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			justifyContent={'space-between'}
			marginTop={'0.7rem'}
		>
			<ResizingButton
				variant="text"
				onClick={() => {
					matomo.trackEvent('Cancel', 'click', t(textCode));
					return closeDialog();
				}}
				style={{
					color: 'rgb(97, 97, 97)',
					fontWeight: 500,
				}}
			>
				{t('cancel')}
			</ResizingButton>
			<LoadingButton
				type="submit"
				variant="contained"
				color="primary"
				loading={isSaving}
				onClick={() => {
					matomo.trackEvent('Add', 'click', t(textCode));
				}}
			>
				{t(textCode)}
			</LoadingButton>
		</Box>
	);
};

export default AddDialogButtons;
