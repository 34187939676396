import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpenseFolder } from "../../Models/ExpenseFolder";
import { addExpenseCases } from "./ExpenseCaseBuilders";
import { addExpenseFolderCases } from "./ExpenseFolderCaseBuilder";
import { ExpenseUnion as ExpenseAlias } from "../../Models/Expense";

export enum Status {
	Fetching,
	Idle,
	Failed,
	Updating,
	Posting,
	Deleting,
}

export interface ExpenseFoldersState {
	//todo: each time an expense changes status should be set to idle, same with folders
	folders: ExpenseFolder[];
	expenseFolderStatus: Status;
	expenseFolderToEdit: ExpenseFolder | null;
	expenseToEdit: ExpenseAlias | null;
	expenseStatus: Status;
}

const initialState: ExpenseFoldersState = {
	folders: [],
	expenseFolderStatus: Status.Fetching,
	expenseStatus: Status.Idle,
	expenseFolderToEdit: null,
	expenseToEdit: null,
};

export const ExpenseFoldersSlice = createSlice({
	name: "expenseFolders",
	initialState,
	reducers: {
		removeSentFolder: (
			state,
			action: PayloadAction<{ sentFolderId: number }>,
		) => {
			state.folders = state.folders.filter(
				(f) => f.id !== action.payload.sentFolderId,
			);
		},
	},
	extraReducers: (builder) => {
		addExpenseFolderCases(builder);
		addExpenseCases(builder);
	},
});

export const { removeSentFolder } = ExpenseFoldersSlice.actions;

export default ExpenseFoldersSlice.reducer;
