export const downloadFile = async (file: File) => {
	try {
		const downloadUrl = window.URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = downloadUrl;
		link.download = file.name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(downloadUrl);
	} catch (error) {
		console.error("Error downloading the file:", error);
	}
};

export const base64ToFile = (data: string, filename: string) => {
	const arr = data.split(",");
	let bstr;
	let mime;
	if (arr.length > 1) {
		mime = RegExp(/:(.*?);/).exec(arr[0])![1];
		bstr = atob(arr[1]);
	} else {
		mime = "application/pdf";
		bstr = atob(data);
	}
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result as string);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
};

export const base64ToPDFFile = (base64: string, fileName: string): File => {
	// Decode base64 string
	const base64EncodedString = base64.replace(
		/^data:application\/pdf;base64,/,
		"",
	);
	const binaryString = window.atob(base64EncodedString);

	// Convert binary string to a typed array
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	// Create a Blob from the typed array
	const blob = new Blob([bytes], { type: "application/pdf" });

	// Create and return a File object
	return new File([blob], fileName, { type: "application/pdf" });
};
