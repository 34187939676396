import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	MileageAllowance,
	BaseExpense,
	Flight,
	Representation,
	OtherTransport,
} from "../../Models/Expense";
import { ExpenseFolder } from "../../Models/ExpenseFolder";
import {
	toMileageAllowanceDto,
	toMileageAllowance,
	toBaseExpenseDto,
	toBaseExpense,
	toFlightDto,
	toFlight,
	toRepresentationDto,
	toRepresentation,
	toOtherTransportDto,
	toOtherTransport,
} from "../../Models/Mappings";
import ApiClient from "../../Services/ApiClient";
import { RootState } from "../Store";

export const deleteExpenseFolder = createAsyncThunk(
	"expenseFolders/deleteFolder",
	async (folder: ExpenseFolder, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.deleteExpenseFolderDelete(folder.id);
		return folder;
	},
);

export const postMileageAllowance = createAsyncThunk(
	"expenseFolders/postMileageAllowance",
	async (mileageAllowance: MileageAllowance, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const response = await ApiClient(
			apiKey!,
		).expense.postMileageAllowanceCreate(
			toMileageAllowanceDto(mileageAllowance),
		);
		return toMileageAllowance(response.data);
	},
);

export const putMileageAllowance = createAsyncThunk(
	"expenseFolders/putMileageAllowance",
	async (mileageAllowance: MileageAllowance, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putMileageAllowanceUpdate(
			toMileageAllowanceDto(mileageAllowance),
		);
		return mileageAllowance;
	},
);

export const postBaseExpense = createAsyncThunk(
	"expenseFolders/postBaseExpense",
	async (baseExpense: BaseExpense, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const response = await ApiClient(apiKey!).expense.postBaseExpenseCreate(
			toBaseExpenseDto(baseExpense),
		);
		return toBaseExpense(response.data);
	},
);

export const putBaseExpense = createAsyncThunk(
	"expenseFolders/putBaseExpense",
	async (baseExpense: BaseExpense, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putBaseExpenseUpdate(
			toBaseExpenseDto(baseExpense),
		);
		return baseExpense;
	},
);

export const postFlight = createAsyncThunk(
	"expenseFolders/postFlight",
	async (baseExpense: Flight, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const response = await ApiClient(apiKey!).expense.postFlightCreate(
			toFlightDto(baseExpense),
		);
		return toFlight(response.data);
	},
);

export const putFlight = createAsyncThunk(
	"expenseFolders/putFlight",
	async (flight: Flight, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putFlightUpdate(toFlightDto(flight));
		return flight;
	},
);

export const postRepresentation = createAsyncThunk(
	"expenseFolders/postRepresentation",
	async (representation: Representation, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const response = await ApiClient(
			apiKey!,
		).expense.postRepresentationCreate(toRepresentationDto(representation));
		return toRepresentation(response.data);
	},
);

export const putRepresentation = createAsyncThunk(
	"expenseFolders/putRepresentation",
	async (representation: Representation, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putRepresentationUpdate(
			toRepresentationDto(representation),
		);
		return representation;
	},
);

export const postOtherTransport = createAsyncThunk(
	"expenseFolders/postOtherTransport",
	async (otherTransport: OtherTransport, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const response = await ApiClient(
			apiKey!,
		).expense.postOtherTransportCreate(toOtherTransportDto(otherTransport));
		return toOtherTransport(response.data);
	},
);

export const putOtherTransport = createAsyncThunk(
	"expenseFolders/putOtherTransport",
	async (otherTransport: OtherTransport, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putOtherTransportUpdate(
			toOtherTransportDto(otherTransport),
		);
		return otherTransport;
	},
);

export const deleteExpense = createAsyncThunk(
	"expenseFolders/deleteExpense",
	async (expenseId: number, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.deleteExpenseDelete(expenseId);
		return expenseId;
	},
);

export const deleteAttachment = createAsyncThunk(
	"expenseFolders/deleteAttachment",
	async (attachmentId: number, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.deleteAttachmentDelete(attachmentId);
		return attachmentId;
	},
);
