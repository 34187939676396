import theme from './Theme';
import React, { ReactNode, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
	AppBar,
	Box,
	Container,
	Menu,
	MenuItem,
	ThemeProvider,
	Toolbar,
	Typography,
	Button,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppSelector } from './Redux/Hooks';
import HistoryIcon from '@mui/icons-material/History';

export default function Layout({
	children,
}: Readonly<{ children: ReactNode }>) {
	const { logout } = useAuth0();
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

	const profile = useAppSelector((state) => state.profileReducer.profile);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const { t } = useTranslation();

	return (
		<ThemeProvider theme={theme}>
			<Box display="flex" flexDirection="column" minHeight="100vh">
				<AppBar position="static">
					<Container maxWidth="md">
						<Toolbar disableGutters>
							<Box
								display="flex"
								alignItems={'center'}
								justifyContent={'space-between'}
								width="100%"
							>
								<Link
									component={RouterLink}
									to={''}
									display={'flex'}
								>
									<img
										id="logo"
										src="/logo.svg"
										alt="Berg-Hansen Utlegg"
										title="Berg-Hansen Utlegg"
										key="logo"
										style={{ width: '70%', height: '70%' }}
									/>
								</Link>

								<Box>
									<Button
										onClick={handleOpenUserMenu}
										variant="text"
										endIcon={
											<KeyboardArrowDownIcon
												style={{
													transform: Boolean(
														anchorElUser,
													)
														? 'rotate(180deg)'
														: ' rotate(0deg)',
													transition:
														'transform 0.3s ease-in-out',
													color: 'black',
												}}
											/>
										}
									>
										<Typography
											sx={{
												color: 'black',
												textAlign: 'right',
											}}
										>
											{profile.firstName}{' '}
											{profile.lastName}
										</Typography>
									</Button>

									<Menu
										sx={{ mt: '45px' }}
										id="menu-appbar"
										anchorEl={anchorElUser}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
									>
										<Link
											sx={{ textDecoration: 'none' }}
											component={RouterLink}
											to={'/settings'}
											color="inherit"
											onClick={handleCloseUserMenu}
										>
											<MenuItem>
												<SettingsOutlinedIcon />
												<Typography
													marginLeft={'0.5rem'}
												>
													{t('settings')}
												</Typography>
											</MenuItem>
										</Link>
										<Link
											href="https://booking.webgate.no"
											color="inherit"
											sx={{ textDecoration: 'none' }}
											target="_blank"
											rel="noopener noreferrer"
											onClick={handleCloseUserMenu}
										>
											<MenuItem>
												<FlightTakeoffOutlinedIcon />
												<Typography
													marginLeft={'0.5rem'}
												>
													Webgate
												</Typography>
											</MenuItem>
										</Link>
										<Link
											sx={{ textDecoration: 'none' }}
											component={RouterLink}
											to={'/history'}
											color="inherit"
											onClick={handleCloseUserMenu}
										>
											<MenuItem>
												<HistoryIcon />
												<Typography
													marginLeft={'0.5rem'}
												>
													{t('previously_submitted')}
												</Typography>
											</MenuItem>
										</Link>
										<MenuItem onClick={handleCloseUserMenu}>
											<LogoutOutlinedIcon />
											<Typography
												marginLeft={'0.5rem'}
												onClick={() =>
													logout({
														logoutParams: {
															returnTo:
																window.location
																	.origin,
														},
													})
												}
												textAlign="center"
											>
												{t('log_out')}
											</Typography>
										</MenuItem>
									</Menu>
								</Box>
							</Box>
						</Toolbar>
					</Container>
				</AppBar>

				<Container
					maxWidth="md"
					sx={{ marginTop: 2, marginBottom: 2, flexGrow: 1 }}
				>
					<main>{children}</main>
				</Container>

				<Box bgcolor="white">
					<Container
						maxWidth="md"
						sx={{ color: 'grey.500', fontSize: 12 }}
					>
						<footer>
							<Box display="flex" alignItems="center" gap={1}>
								<img
									id="footer-logo"
									src="/bh-logo.svg"
									alt="Berg-Hansen Utlegg"
									title="Berg-Hansen Utlegg"
									key="mini-logo"
								/>
								<p style={{ color: '#404040' }}>
									&copy; 2024 Berg Hansen
								</p>
							</Box>
							<p>
								<a
									href="https://www.berg-hansen.no/personvernerklaering/"
									target="_blank"
								>
									{t('privacy_statement')}
								</a>
							</p>
						</footer>
					</Container>
				</Box>
			</Box>
		</ThemeProvider>
	);
}
