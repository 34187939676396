import { ExpenseUnion } from "../Models/Expense";
import { ExpenseFolder } from "../Models/ExpenseFolder";

export const concatExpenses = (
	expenseFolder: ExpenseFolder,
): ExpenseUnion[] => {
	return (
		expenseFolder.baseExpenses
			?.concat(expenseFolder.flight ?? [])
			.concat(expenseFolder.mileageAllowances ?? [])
			.concat(expenseFolder.representations ?? [])
			.concat(expenseFolder.otherTransports ?? []) ?? []
	);
};
