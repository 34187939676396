import { Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import { ExpenseFolder } from '../Models/ExpenseFolder';
import CloseIconButtonComponent from './Shared/CloseIconButtonComponent';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useEffect, useState } from 'react';
import AddDialogButtons from './Shared/AddDialogButtons';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { postExpenseFolder } from '../Redux/ExpenseFoldersSlice/ExpenseFolderThunks';
import { Status } from '../Redux/ExpenseFoldersSlice/ExpenseFoldersSlice';
import { closeAddFolderDialog } from '../Redux/Uislice';
import { useTranslation } from 'react-i18next';

const InitExpenseFolder = (
	profileId: number,
	currencyCode: string,
): ExpenseFolder => {
	return {
		description: '',
		profileId: profileId,
		currencyCode: currencyCode,
		id: 0,
		otherTransports: [],
		baseExpenses: [],
		representations: [],
		mileageAllowances: [],
		flight: [],
		createdAt: new Date().toISOString(),
	};
};

const AddExpenseFolderDialog = () => {
	const [expenseFolder, setExpenseFolder] = useState<ExpenseFolder>();
	const profile = useAppSelector((state) => state.profileReducer.profile);
	const isSaving = useAppSelector(
		(state) => state.expenseFolderReducer.expenseStatus,
	);
	const open = useAppSelector(
		(state) => state.uiReducer.isAddExpenseFolderDialogOpen,
	);
	const dispatch = useAppDispatch();

	const { t } = useTranslation();

	useEffect(() => {
		setExpenseFolder(InitExpenseFolder(profile.id, profile.currencyCode));
	}, [profile]);

	const submit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await dispatch(postExpenseFolder(expenseFolder!));
		dispatch(dispatch(closeAddFolderDialog()));
	};

	const handleFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setExpenseFolder({
			...expenseFolder!,
			description: e.target.value,
		});
	};

	const onClose = () => {
		dispatch(closeAddFolderDialog());
	};

	return (
		<Dialog open={open} onClose={onClose} sx={{ minWidth: '20rem' }}>
			<CloseIconButtonComponent onClick={onClose} />
			<DialogTitle
				display={'flex'}
				alignItems={'center'}
				marginTop="0rem"
				paddingTop="0rem"
			>
				<FolderOutlinedIcon sx={{ marginRight: '0.4rem' }} />
				{t('new_expense_folder')}
			</DialogTitle>

			<DialogContent>
				<form onSubmit={submit}>
					<TextField
						autoFocus
						id="name"
						label={t('name')}
						type="text"
						value={expenseFolder?.description ?? ''}
						onChange={handleFolderNameChange}
						required
					/>

					<AddDialogButtons
						closeDialog={onClose}
						isAdding={isSaving === Status.Fetching}
						textCode="add_expense_folder"
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default AddExpenseFolderDialog;
