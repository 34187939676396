/**
 * A small helperclass to set defaults for the swagger client
 */
import { Api } from "../Services/SwaggerClient";

export default  function ApiClient(token: string) {
	//if(expired) updateToken() 
	return new Api({ baseUrl: process.env.REACT_APP_API_BASE_URL as string, baseApiParams: {
		headers: {
			'Authorization': 'Bearer ' + token
		}
	}});
}
