import { Backdrop, CircularProgress, Typography } from '@mui/material'

export default function Loading({ message = "Loading..." }) {
	return (
		<Backdrop sx={{ color: '#fff' }} open={true}>
			<Typography variant='body2'>
				<CircularProgress sx={{ color: 'inherit', display: 'block', marginBottom: 2, marginLeft: 'auto', marginRight: 'auto' }} />
				{message}
			</Typography>
		</Backdrop>
	)
}
