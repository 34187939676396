import { User } from "@auth0/auth0-react";
import {
	BergHansenModelsExpenseAttachment,
	BergHansenModelsExpenseBaseExpense,
	BergHansenModelsExpenseBhExpense,
	BergHansenModelsExpenseCurrency,
	BergHansenModelsExpenseExpenseFolder,
	BergHansenModelsExpenseExpenseFolderPdf,
	BergHansenModelsExpenseExpenseName,
	BergHansenModelsExpenseFlight,
	BergHansenModelsExpenseM3ExpenseName,
	BergHansenModelsExpenseMileageAllowance,
	BergHansenModelsExpenseOtherTransport,
	BergHansenModelsExpenseProfile,
	BergHansenModelsExpenseRepresentation,
	ExpenseExpenseFolderPdf,
} from "../Services/SwaggerClient";
import { Attachment } from "./Attachment";
import {
	BaseExpense,
	Expense,
	ExpenseName,
	ExpenseUnion,
	Flight,
	MileageAllowance,
	OtherTransport,
	Representation,
} from "./Expense";
import { ExpenseFolder } from "./ExpenseFolder";
import { ExpenseFolderPdf } from "./ExpenseFolderPdf";
import { Profile } from "./Profile";
import { Currency } from "./Currency";

export const toAttachment = (
	attachment: BergHansenModelsExpenseAttachment,
): Attachment => {
	return {
		expenseId: attachment.expenseId!,
		id: attachment.id!,
		description: attachment.description,
		mediaType: attachment.mediaType!,
		data: attachment.data!,
		url: attachment.url,
	};
};

export const toExpense = (
	expense:
		| BergHansenModelsExpenseBaseExpense
		| BergHansenModelsExpenseRepresentation
		| BergHansenModelsExpenseFlight
		| BergHansenModelsExpenseOtherTransport
		| BergHansenModelsExpenseMileageAllowance,
): BaseExpense => {
	if (expense.expenseName === BergHansenModelsExpenseExpenseName.Representation) {
		return toRepresentation(expense as BergHansenModelsExpenseRepresentation);
	}
	if (
		expense.expenseName === BergHansenModelsExpenseExpenseName.MileageAllowance
	) {
		return toMileageAllowance(
			expense as BergHansenModelsExpenseMileageAllowance,
		);
	}
	if (expense.expenseName === BergHansenModelsExpenseExpenseName.Flight) {
		return toFlight(expense as BergHansenModelsExpenseFlight);
	}

	if (expense.expenseName === BergHansenModelsExpenseExpenseName.OtherTransport) {
		return toOtherTransport(expense as BergHansenModelsExpenseOtherTransport);
	}

	return toBaseExpense(expense as BergHansenModelsExpenseBaseExpense);
};

export const toFlight = (expense: BergHansenModelsExpenseFlight): Flight => {
	return {
		id: expense.id!,
		date: expense.expenseDate!,
		expenseFolderId: expense.expenseReportId!,
		currencyCode: expense.currencyCode ?? "",
		amount: expense.amount ?? 0,
		attachments: expense.attachments?.map(toAttachment),
		expenseName:
			ExpenseName[expense.expenseName as keyof typeof ExpenseName],
		endLocation: expense.endLocation ?? "",
		startLocation: expense.startLocation ?? "",
		createdAt: expense.createdAt!,
		m3OrderNumber: expense.m3OrderNumber ?? undefined,
	};
};

export const toFlightFromBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
	startLocation: string,
	endLocation: string,
): Flight => {
	return {
		id: 0,
		date: bhExpense.date!,
		expenseFolderId: expenseFolderId,
		currencyCode: bhExpense.currency ?? "",
		amount: bhExpense.amount ?? 0,
		attachments: [
			{
				expenseId: 0,
				id: 0,
				description: bhExpense.date?.toString() ?? "receipt",
				mediaType: "pdf",
				data: bhExpense.data!,
				url: bhExpense.url,
			},
		],
		expenseName: ExpenseName.OtherTransport,
		createdAt: new Date().toISOString(),
		startLocation: startLocation,
		endLocation: endLocation,
	};
};

const toExpenseFromBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
) => {
	return {
		id: 0,
		date: bhExpense.date!,
		expenseFolderId: expenseFolderId,
		description: bhExpense.description ?? " ",
		currencyCode: bhExpense.currency ?? "NOK",
		amount: bhExpense.amount ?? 0,
		attachments: bhExpense.url
			? [
					{
						expenseId: 0,
						id: 0,
						description:
							(bhExpense.description ?? "receipt") + ".pdf",
						mediaType: "pdf",
						data: bhExpense.data!,
						url: bhExpense.url,
					},
			  ]
			: [],
		expenseName: ToExpenseNameFromBhExpense(bhExpense.type!),
		createdAt: new Date().toISOString(),
		m3OrderNumber: bhExpense.orderNumber,
	};
};

export const toBaseExpenseBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
): BaseExpense => {
	return {
		...toExpenseFromBhExpense(bhExpense, expenseFolderId),
		description: bhExpense.description ?? bhExpense.type!.toString(),
	};
};

export const toFlightBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
	startLocation: string,
	endLocation: string,
): Flight => {
	return {
		...toExpenseFromBhExpense(bhExpense, expenseFolderId),
		startLocation: startLocation,
		endLocation: endLocation,
	};
};

export const toOtherTransportBhExpense = (
	bhExpense: BergHansenModelsExpenseBhExpense,
	expenseFolderId: number,
	startLocation: string,
	endLocation: string,
): OtherTransport => {
	return {
		...toExpenseFromBhExpense(bhExpense, expenseFolderId),
		startLocation: startLocation ?? "",
		endLocation: endLocation ?? "",
		description: bhExpense.description ?? bhExpense.type!.toString(),
	};
};

export const toOtherTransport = (
	expense: BergHansenModelsExpenseOtherTransport,
): OtherTransport => {
	return {
		id: expense.id!,
		date: expense.expenseDate!,
		expenseFolderId: expense.expenseReportId!,
		description: expense.description ?? "",
		currencyCode: expense.currencyCode ?? "",
		amount: expense.amount ?? 0,
		attachments: expense.attachments?.map(toAttachment),
		expenseName:
			ExpenseName[expense.expenseName as keyof typeof ExpenseName],
		endLocation: expense.endLocation ?? "",
		startLocation: expense.startLocation ?? "",
		createdAt: expense.createdAt!,
		m3OrderNumber: expense.m3OrderNumber ?? undefined,
	};
};

export const toBaseExpense = (
	expense: BergHansenModelsExpenseBaseExpense,
): BaseExpense => {
	return {
		...toExpenseBase(expense),
		description: expense.description ?? "",
	};
};

export const toRepresentation = (
	representation: BergHansenModelsExpenseRepresentation,
): Representation => {
	return {
		id: representation.id!,
		date: representation.expenseDate!,
		expenseFolderId: representation.expenseReportId!,
		currencyCode: representation.currencyCode ?? "",
		amount: representation.amount ?? 0,
		attachments: representation.attachments?.map(toAttachment),
		expenseName:
			ExpenseName[representation.expenseName as keyof typeof ExpenseName],
		who: representation.who ?? "",
		where: representation.where ?? "",
		createdAt: representation.createdAt!,
		m3OrderNumber: representation.m3OrderNumber ?? undefined,
	};
};

export const toMileageAllowance = (
	mileageAllowance: BergHansenModelsExpenseMileageAllowance,
): MileageAllowance => {
	return {
		id: mileageAllowance.id!,
		date: mileageAllowance.expenseDate!,
		expenseFolderId: mileageAllowance.expenseReportId!,
		currencyCode: mileageAllowance.currencyCode ?? "",
		amount: mileageAllowance.amount ?? 0,
		attachments: mileageAllowance.attachments?.map(toAttachment),
		expenseName:
			ExpenseName[
				mileageAllowance.expenseName as keyof typeof ExpenseName
			],
		distanceInKm: mileageAllowance.distanceInKm ?? 0,
		passengerCount: mileageAllowance.passengerCount ?? 0,
		createdAt: mileageAllowance.createdAt!,
		startLocation: mileageAllowance.startLocation ?? "",
		endLocation: mileageAllowance.endLocation ?? "",
		m3OrderNumber: mileageAllowance.m3OrderNumber ?? undefined,
	};
};

export const toExpenseFolder = (
	expenseFolder: BergHansenModelsExpenseExpenseFolder,
): ExpenseFolder => {
	return {
		id: expenseFolder.id!,
		description: expenseFolder.description ?? "",
		flight: expenseFolder.flight?.map(toFlight) ?? [],
		representations:
			expenseFolder.representations?.map(toRepresentation) ?? [],
		mileageAllowances:
			expenseFolder.mileageAllowances?.map(toMileageAllowance) ?? [],
		baseExpenses: expenseFolder.baseExpense?.map(toBaseExpense) ?? [],
		otherTransports:
			expenseFolder.otherTransports?.map(toOtherTransport) ?? [],
		profileId: expenseFolder.profileId!,
		submitDate: expenseFolder.submitDate,
		currencyCode: expenseFolder.currencyCode ?? "",
		userSetEndDate: expenseFolder.userSetEndDate,
		userSetFromDate: expenseFolder.userSetStartDate,
		createdAt: expenseFolder.createdAt!,
	};
};

export const toExpenseFolderPdf = (
	pdf: ExpenseExpenseFolderPdf,
): ExpenseFolderPdf => {
	return {
		data: pdf.data!,
		id: pdf.id!,
		profileId: pdf.profileId!,
		description: pdf.description ?? "",
		startDate: pdf.startDate!,
		endDate: pdf.endDate ?? "",
		amount: pdf.amount ?? 0,
		currencyCode: pdf.currencyCode!,
		createdAt: pdf.createdAt!,
		source: pdf.source!,
	};
};

export const toProfile = (profile: BergHansenModelsExpenseProfile): Profile => {
	return {
		id: profile.id!,
		sendTo: profile.sendTo ?? "",
		email: profile.email ?? "",
		currencyCode: profile.currencyCode ?? "",
		ratePerKm: profile.ratePerKm ?? 0,
		ratePerPassenger: profile.ratePerPassenger ?? 0,
		creditCardFee: profile.creditCardFee ?? 0,
		languageCode: profile.languageCode ?? "",
		bankAccount: profile.bankAccount ?? "",
		tacNo: profile.tacNo,
		firstName: profile.firstName ?? "",
		lastName: profile.lastName ?? "",
	};
};

export const toAttachmentDto = (
	attachment: Attachment,
): BergHansenModelsExpenseAttachment => {
	return {
		expenseId: attachment.expenseId,
		id: attachment.id,
		description: attachment.description,
		mediaType: attachment.mediaType,
		data: attachment.data,
		url: attachment.url,
	};
};

export const toRepresentationDto = (
	representation: Representation,
): BergHansenModelsExpenseRepresentation => {
	return {
		...toExpenseDtoBase(representation),
		who: representation.who,
		where: representation.where,
	};
};
export const toFlightDto = (expense: Flight): BergHansenModelsExpenseFlight => {
	return {
		...toExpenseDtoBase(expense),
		startLocation: expense.startLocation,
		endLocation: expense.endLocation,
	};
};
export const toMileageAllowanceDto = (
	mileageAllowance: MileageAllowance,
): BergHansenModelsExpenseMileageAllowance => {
	return {
		...toExpenseDtoBase(mileageAllowance),
		distanceInKm: mileageAllowance.distanceInKm,
		passengerCount: mileageAllowance.passengerCount,
		startLocation: mileageAllowance.startLocation,
		endLocation: mileageAllowance.endLocation,
	};
};

export const toBaseExpenseDto = (
	baseExpense: BaseExpense,
): BergHansenModelsExpenseBaseExpense => {
	return {
		...toExpenseDtoBase(baseExpense),
		description: baseExpense.description,
	};
};

export const toOtherTransportDto = (toOtherTransport: OtherTransport) => {
	return {
		...toExpenseDtoBase(toOtherTransport),
		description: toOtherTransport.description,
		startLocation: toOtherTransport.startLocation,
		endLocation: toOtherTransport.endLocation,
	};
};

function toExpenseBase(expense: BergHansenModelsExpenseBaseExpense): Expense {
	return {
		id: expense.id!,
		date: expense.expenseDate!,
		expenseFolderId: expense.expenseReportId!,
		currencyCode: expense.currencyCode ?? "",
		amount: expense.amount ?? 0,
		attachments: expense.attachments?.map(toAttachment),
		expenseName:
			ExpenseName[expense.expenseName as keyof typeof ExpenseName],
		createdAt: expense.createdAt!,
		m3OrderNumber: expense.m3OrderNumber ?? undefined,
	};
}

function toExpenseDtoBase(
	baseExpense: ExpenseUnion,
): BergHansenModelsExpenseBaseExpense {
	return {
		id: baseExpense.id,
		expenseDate: baseExpense.date,
		expenseReportId: baseExpense.expenseFolderId,
		currencyCode: baseExpense.currencyCode,
		amount: baseExpense.amount,
		attachments: baseExpense.attachments?.map(toAttachmentDto),
		expenseName:
			BergHansenModelsExpenseExpenseName[
				baseExpense.expenseName as keyof typeof BergHansenModelsExpenseExpenseName
			],
		createdAt: baseExpense.createdAt,
		m3OrderNumber: baseExpense.m3OrderNumber,
	};
}

export const toExpenseFolderDto = (
	expenseFolder: ExpenseFolder,
): BergHansenModelsExpenseExpenseFolder => {
	return {
		id: expenseFolder.id,
		description: expenseFolder.description,
		flight: expenseFolder.flight?.map(toFlightDto),
		representations:
			expenseFolder.representations?.map(toRepresentationDto),
		totalAmount: 0,
		mileageAllowances: expenseFolder.mileageAllowances?.map(
			toMileageAllowanceDto,
		),
		baseExpense: expenseFolder.baseExpenses?.map(toBaseExpenseDto),
		otherTransports:
			expenseFolder.otherTransports?.map(toOtherTransportDto),
		profileId: expenseFolder.profileId,
		endDate: null,
		startDate: null,
		currencyCode: expenseFolder.currencyCode,
		userSetEndDate: expenseFolder.userSetEndDate ?? null,
		userSetStartDate: expenseFolder.userSetFromDate ?? null,
		submitDate: expenseFolder.submitDate,
		createdAt: expenseFolder.createdAt,
	};
};

export const toExpenseFolderPdfDto = (
	pdf: ExpenseFolderPdf,
): BergHansenModelsExpenseExpenseFolderPdf => {
	return {
		data: pdf.data,
		id: pdf.id,
		profileId: pdf.profileId,
		description: pdf.description,
		startDate: pdf.startDate,
		endDate: pdf.endDate,
		amount: parseFloat(pdf.amount.toString()),
		currencyCode: pdf.currencyCode,
		createdAt: pdf.createdAt,
	};
};

export const toProfileDto = (profile: Profile): BergHansenModelsExpenseProfile => {
	return {
		id: profile.id,
		firstName: profile.firstName,
		lastName: profile.lastName,
		sendTo: profile.sendTo,
		email: profile.email,
		currencyCode: profile.currencyCode,
		ratePerKm: profile.ratePerKm,
		ratePerPassenger: profile.ratePerPassenger,
		creditCardFee: profile.creditCardFee,
		languageCode: profile.languageCode,
		bankAccount: profile.bankAccount,
		tacNo: profile.tacNo,
	};
};

export const toProfileDtoFromUser = (
	user: User,
): BergHansenModelsExpenseProfile => {
	return {
		id: 0,
		sendTo: "",
		email: user.email!,
		currencyCode: "NOK",
		ratePerKm: 4.9,
		ratePerPassenger: 1,
		creditCardFee: 0,
		expenseFolders: [],
		expenseFolderPdfs: [],
		languageCode: "",
		bankAccount: "",
		tacNo: user.TacNo!,
		firstName: "",
		lastName: "",
	};
};

export const ToExpenseNameFromBhExpense = (
	expenseName: BergHansenModelsExpenseM3ExpenseName,
): ExpenseName => {
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.AirportTrain)
		return ExpenseName.Train;
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.Flight)
		return ExpenseName.Flight;
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.Hotel)
		return ExpenseName.Hotel;
	if (expenseName === BergHansenModelsExpenseM3ExpenseName.Taxi)
		return ExpenseName.OtherTransport;
	return ExpenseName.Other;
};

export const toExpenseName = (expenseName: string): ExpenseName => {
	return ExpenseName[expenseName as keyof typeof ExpenseName];
};

export const toCurrency = (
	currencyDto: BergHansenModelsExpenseCurrency,
): Currency => ({
	name: currencyDto.name!,
	code: currencyDto.code!,
});
