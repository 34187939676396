// src/matomo/Matomo.ts

import  MatomoTracker  from "@datapunt/matomo-tracker-js";

export class Matomo {
	private matomoTracker: MatomoTracker;

	constructor() {
		this.matomoTracker = new MatomoTracker({
			urlBase: "https://berghansen.matomo.cloud/", // URL of your Matomo server
			siteId: 9, // Your Matomo site ID
		});
	}

	trackPageView(pathname: string): void {
		this.matomoTracker.trackPageView({
			href: `${window.location.origin}${pathname}`,
		});
	}

	trackEvent(
		category: string,
		action: string,
		name?: string,
		value?: number,
	): void {
		this.matomoTracker.trackEvent({
			category,
			action,
			name,
			value,
		});
	}
}

export const matomo = new Matomo();
