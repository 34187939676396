import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./Store";
import { Currency } from "../Models/Currency";
import ApiClient from "../Services/ApiClient";
import { toCurrency } from "../Models/Mappings";

enum CurrencyStatus {
	Set,
	Loading,
	Failed,
	NotSet,
}

interface CurrencyState {
	currencyList: Currency[];
	state: CurrencyStatus;
}

const initialState: CurrencyState = {
	currencyList: [],
	state: CurrencyStatus.NotSet,
};

export const getCurrencies = createAsyncThunk(
	"currency/getCurrencies",
	async (_, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const res = await ApiClient(apiKey!).expense.getCurrenciesList();
		return res.data;
	},
);

const currencySlice = createSlice({
	name: "currency",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getCurrencies.pending, (state) => {
				state.state = CurrencyStatus.Loading;
			})
			.addCase(getCurrencies.fulfilled, (state, action) => {
				state.state = CurrencyStatus.Set;
				state.currencyList = action.payload.map((c) => {
					return toCurrency(c);
				});
			})
			.addCase(getCurrencies.rejected, (state) => {
				state.state = CurrencyStatus.Failed;
				state.currencyList = [
					{ code: "NOK", name: "Norske kroner" },
					{ code: "SEK", name: "Svenske kroner" },
					{ code: "DKK", name: "Danske kroner" },
					{ code: "EUR", name: "Euro" },
				];
			});
	},
});

export default currencySlice.reducer;
