import { Box, Tab, Dialog, useMediaQuery } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import BhTrips from './BhTrips';
import { useEffect, useState } from 'react';
import CloseIconButtonComponent from './Shared/CloseIconButtonComponent';
import AddExpenseFormComponent from './AddExpenseFormComponent';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { closeAddExpenseDialog } from '../Redux/Uislice';
import { useTranslation } from 'react-i18next';

enum Tabs {
	EditOrAddExpense = '1',
	BhTrips = '2',
}

const AddExpenseDialogComponent: React.FC = () => {
	const [tab, setTab] = useState<Tabs>(Tabs.EditOrAddExpense);

	const isOnMobile = useMediaQuery('(max-width:600px)');

	const open = useAppSelector(
		(state) => state.uiReducer.isAddExpenseDialogOpen,
	);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleTabChange = (
		_event: React.ChangeEvent<{}>,
		tabValue: string,
	) => {
		setTab(tabValue as Tabs);
	};

	const closeDialog = () => {
		dispatch(closeAddExpenseDialog());
	};

	useEffect(() => {
		setTab(Tabs.EditOrAddExpense);
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={closeDialog}
			fullScreen={isOnMobile}
			maxWidth="md"
			fullWidth
		>
			<TabContext value={tab}>
				<Box
					sx={{ borderBottom: 1, borderColor: 'divider' }}
					display={'flex'}
					justifyContent={'space-between'}
				>
					<TabList
						onChange={handleTabChange}
					>
						<Tab
							sx={{
								fontWeight: 'bold',
							}}
							label={t('new_receipt')}
							value="1"
						/>
						<Tab
							sx={{
								fontWeight: 'bold',
							}}
							label={t('berg_hansen_travels')}
							value="2"
						/>
					</TabList>
					{!isOnMobile && (
						<CloseIconButtonComponent onClick={closeDialog} />
					)}
				</Box>
				<TabPanel value="1">
					<AddExpenseFormComponent />
				</TabPanel>
				<TabPanel value="2">
					<BhTrips />
				</TabPanel>
			</TabContext>
		</Dialog>
	);
};

export default AddExpenseDialogComponent;
