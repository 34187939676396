import { LoadingButton } from '@mui/lab';
import { TableRow, TableCell, Checkbox } from '@mui/material';
import { processOrder } from '../Redux/BhExpensesSlice';
import { formatDate } from '../Utils/DateFormatter';
import { BergHansenM3ClientModelsWebgateGetOrdersOrder } from '../Services/SwaggerClient';
import { useAppDispatch } from '../Redux/Hooks';
import { useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { t } from 'i18next';

interface BhTripsRowProps {
	bergHansenOrder: BergHansenM3ClientModelsWebgateGetOrdersOrder;
	handleToggle: (id: number) => void;
}

const BhTableRow = (props: BhTripsRowProps) => {
	const { bergHansenOrder, handleToggle } = props;
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleButtonClick = async () => {
		setIsLoading(true);
		try {
			await dispatch(
				processOrder(parseInt(bergHansenOrder.orderNo!)),
			).unwrap();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<TableRow key={bergHansenOrder.orderNo}>
			<TableCell padding="checkbox">
				<Checkbox
					onChange={() =>
						handleToggle(parseInt(bergHansenOrder.orderNo!))
					}
				/>
			</TableCell>
			<TableCell>{bergHansenOrder.destDisp}</TableCell>
			<TableCell sx={{ fontWeight: '300' }}>
				{formatDate(bergHansenOrder.outward)}
			</TableCell>
			<TableCell width={'40%'} align="right">
				<LoadingButton onClick={handleButtonClick} loading={isLoading}>
					<DeleteOutlineIcon titleAccess={t("delete_expense_folder")} />
				</LoadingButton>
			</TableCell>
		</TableRow>
	);
};

export default BhTableRow;
