import { createAsyncThunk } from "@reduxjs/toolkit";
import { ExpenseFolder } from "../../Models/ExpenseFolder";
import { toExpenseFolder, toExpenseFolderDto } from "../../Models/Mappings";
import ApiClient from "../../Services/ApiClient";
import { RootState } from "../Store";

export const getExpenseFolders = createAsyncThunk(
	"expenseFolders/fetchFolders",
	async (_, thunkApi) => {
		const profileId = (thunkApi.getState() as RootState).profileReducer
			.profile.id;
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const res = await ApiClient(apiKey!).expense.getExpenseFoldersDetail(
			profileId,
		);
		return res.data.map(toExpenseFolder);
	},
);

export const postExpenseFolder = createAsyncThunk(
	"expenseFolders/postFolder",
	async (folder: ExpenseFolder, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		const res = await ApiClient(apiKey!).expense.postExpenseFolderCreate(
			toExpenseFolderDto(folder),
		);
		return toExpenseFolder(res.data);
	},
);

export const putExpenseFolder = createAsyncThunk(
	"expenseFolders/putFolder",
	async (folder: ExpenseFolder, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.putExpenseFolderUpdate(
			toExpenseFolderDto(folder),
		);
		return folder;
	},
);

export const deleteExpenseFolder = createAsyncThunk(
	"expenseFolders/deleteFolder",
	async (folderId: number, thunkApi) => {
		const apiKey = (thunkApi.getState() as RootState).apiKey.value;
		await ApiClient(apiKey!).expense.deleteExpenseFolderDelete(folderId);
		return folderId;
	},
);
