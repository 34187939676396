import React, { forwardRef, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface NumberInputFieldProps
	extends Omit<TextFieldProps, 'value' | 'onChange'> {
	value: number;
	onChange: (amount: number) => void;
	maxValue?: number;
}

const NumberInputField = forwardRef<HTMLInputElement, NumberInputFieldProps>(
	({ value: amount, onChange, maxValue, ...rest }, ref) => {
		const [value, setValue] = useState<string>('');

		const language = navigator.language || 'en-US';

		useEffect(() => {
			if (amount === 0) setValue('');
			if (!amount) setValue('');
			else
				setValue(
					language === 'en-US'
						? amount.toString()
						: amount.toString().replace('.', ','),
				);
		}, []);

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const inputValue = event.target.value;
			if (inputValue === '') {
				onChange(0);
				setValue('');
				return;
			}

			const regex = /^[0-9]+([.,][0-9]*)?$/;

			if (
				regex.test(inputValue) &&
				(!maxValue ||
					parseFloat(inputValue.replace(',', '.')) <= maxValue)
			) {
				setValue(inputValue);

				const amount = parseFloat(inputValue.replace(',', '.'));
				onChange(amount);
			}
		};

		return (
			<TextField
				variant="outlined"
				value={value}
				onChange={handleChange}
				required
				inputRef={ref}
				{...rest}
			/>
		);
	},
);

export default NumberInputField;
