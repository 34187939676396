import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { store } from './Redux/Store';
import './i18n';
import { init as initApm } from '@elastic/apm-rum';


import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const apm =
	process.env.NODE_ENV === 'production'
		? initApm({
				serviceName: 'Expense',
				serverUrl:
					'https://438a429bf63847019df6c867c908c9cd.apm.westeurope.azure.elastic-cloud.com:443',
				serviceVersion: '',
				environment: 'PRODUCTION',
		  })
		: null;

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
				clientId={process.env.REACT_APP_AUTH0_CLIENTID as string}
				authorizationParams={{
					redirect_uri: window.location.origin,
					audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				}}
			>
				<Provider store={store}>
					<App />
				</Provider>
			</Auth0Provider>
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
